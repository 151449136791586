import React,{useContext} from 'react'
import { NavLink } from '../../styles/Links'

import {
    Box,
    Button,
    Paper,
} from '@mui/material'

import {Context} from '../../context/Context'

const MenuFormPreview = () => {
    const {createMenu} = useContext(Context)
        console.log(createMenu)
    return (
        <Paper sx={{
            width: {md: '60vw', xs: '90vw'},
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            bgcolor: 'none',
            mt: 4,
            mb: 8,
            padding: 2,
        }}>
            {/* {createMenu.map(item =>( */}

            <table>
                <tr>
                    <td><Box>image</Box></td>
                </tr>
                <tr>
                    <td>food name</td>
                    <td>{createMenu.foodName}</td>
                </tr>
                <tr>
                    <td>food price</td>
                    <td>{createMenu.foodPrice}</td>
                </tr>
                <tr>
                    <td>Basic ingrident</td>
                    <td>data</td>
                </tr>
                <tr>
                    <td>Detail ingrident</td>
                    <td>data</td>
                </tr>
                <tr>
                    <td>Preparation</td>
                    <td>data</td>
                </tr>
                <tr>
                    <td>Preservation</td>
                    <td>data</td>
                </tr>
                <tr>
                    <td>
                        <NavLink to="/menuform">
                        <Button variant="contained" color="secondary">
                            Edit
                        </Button>
                        </NavLink>
                        </td>
                    <td><Button variant="contained" color="success">Submit</Button></td>
                </tr>
            </table>
            {/* ))} */}
        </Paper>
    )
}

export default MenuFormPreview
