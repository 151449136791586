import React from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    Typography,
} from '@mui/material'

import {
    Link
} from 'react-router-dom'


const MenuCard = ({foodName, price, mainIngrident, order, handleOrder}) => {
    // console.log(props)
    return (
        <Card>
            <CardMedia 
            
            />
            <CardContent>
                <Typography variant="subtitle1" color="gray">Menu: {foodName}</Typography>
                <Typography variant="subtitle1" color="gray">Price: {`N${price}`}</Typography>
                <Typography variant="subtitle1" color="gray">Main ingrident: {mainIngrident}</Typography>
            </CardContent>
            <CardActions> 
                    <Button variant="contained" color="success" onClick={()=>handleOrder(order)}>Order</Button>
                    <Button variant="outlined" color="info">
                        <Link to={`/detail/${order}`}
                        style={{textDecoration: 'none', color: "inherit"}}
                        >
                            View detail
                        </Link>
                    </Button> 
            </CardActions>
        </Card>
    )
}

export default MenuCard
