import { createContext, useReducer, useState } from "react";



const INISTIAL_STATE = {
    foodPhoto: null,
    foodName: "",
    foodPrice: "",
    basicIngrident: "",
    detailIngrident: "",
    preparation: "",
    Preservation: "",
}

export const Context = createContext()


const MenuFormProvider = (props) =>{
    const [createMenu, setCreateMenu] = useState(INISTIAL_STATE)

    
    const previewForm = (e) =>{
        e.preventDefault()
        setCreateMenu(createMenu)
        // window.location.replace('/formpreview')
        console.log(createMenu)
    }
    
    const submitForm = () =>{
        
    }

    const value = {
        createMenu,
        setCreateMenu,
        previewForm,
        submitForm,
    }


    return(
        <Context.Provider value={value}>
            {props.children}
        </Context.Provider>
    )
}

export default MenuFormProvider
