import React from 'react'

import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
} from '@mui/material'


const LoginForm = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: 'none',
            padding: 2,
            height: '85vh'
        }}>
           <Paper sx={{
            width: {md: '35vw', xs: '90vw'},
            bgcolor: 'none',
            padding: 2,
        }}>
            <form>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            fullWidth
                            label="Enter your email..."
                            name="foodName"
                        // onChange={ handleChange}                      
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            fullWidth
                            type="password"
                            label="Enter your password..."
                            name="foodPrice"
                        //    onChange={ handleChange}                       
                           />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button variant="contained"  type="submit" color="primary" sx={{mr:2}}>Login</Button>
                        <Button variant="outlined"  type="submit" >Create Account</Button>
                    </Grid>
                </Grid>
            </form>
           </Paper>
        </Box>
    )
}

export default LoginForm
