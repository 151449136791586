import React from 'react';
import {
    Box,
    CssBaseline
} from '@mui/material'



import {
    BrowserRouter as Router, 
    Route, 
} from 'react-router-dom'


import Menu from './components/menu/Menu';
import DetailPage from './components/menu/DetailPage';
import NavHeader from './components/header'; 
import MenuForm from './components/menuform/MenuForm';
import MenuFormPreview from './components/menuform/MenuFormPreview';
import LoginForm from './components/loginform/LoginForm';



const App = () => {
    // const user = false
    return (
        <Box>
            <Router>
                <Box>
                    <CssBaseline />
                    <NavHeader />
                </Box>
                <Route path="/" exact component={Menu} />
                <Route path="/detail" component={DetailPage} />
                <Route path="/menuform" component={MenuForm} />
                <Route path="/formpreview" component={MenuFormPreview} />
                <Route path="/loginForm" component={LoginForm} />
            </Router>
        </Box>
    )
}

export default App
