import React,{useEffect, useState} from 'react';
import axios from 'axios';
import MenuCard from './MenuCard';


import {
    Grid,
    Container,
    Typography,
    Box,
} from '@mui/material'


const Menu = () => {
    const [menu, setMenu] = useState([])

    useEffect(() =>{
        const displayItem = async()=>{
            try {
                const res = await axios.get('/api')
                // console.log(res)
                setMenu(res.data)
            } catch (error) {
                
            }
        }
        displayItem()
    },[])



const handleOrder = (id) =>(
    alert(id)
)
    


const DisplayMenu = () => {
    return(
        menu.map(item =>(
            <Grid item key={item._id} xs={12} sm={4} md={3} sx={{mt: 4}}>
                <MenuCard 
                    foodPhoto={item.foodPhoto}
                    foodName={item.foodName}
                    price={item.price}
                    mainIngrident={item.mainIngrident}
                    order={item._id}
                    handleOrder={handleOrder}
                />
            </Grid>
        ))
    )
}



    return (
        <Container>
            <Box sx={{
                display: {xs: 'block', md: 'flex',},
                mt: 1,
            }}>
                <Box sx={{
                    // width: {md: '50vw', xs: '90vw'},
                    flex: {md: 8, xs: '12'},
                    height: '30vh',
                    background: 'gainsboro',
                    marginBottom: {xs: 2}
                }}>
                </Box>
                <Box sx={{
                    flex: {md: 4, xs: '12'},
                    padding: 2,
                }}>
                    <Typography variant="h4" color="gray">3 Steps to Stop Hunger</Typography>
                    <ul>
                        <li>Create Account</li>
                        <li>Fund your wallet</li>
                        <li>Order for food</li>
                    </ul>
                </Box>
            </Box>
            <Grid container spacing={2}>
                <DisplayMenu />
            </Grid>
        </Container>
    )
}

export default Menu
