import React,{useState} from 'react'

import {
    AppBar,
    Toolbar,
    Box,
    Typography,
} from '@mui/material'


import {
    ShoppingCart,
    RestaurantMenuRounded,
    MoreVert
} from '@material-ui/icons'


import { NavLink, styles } from '../../styles/Links'

const HeaderDesktop = () => {
    const [subMenu, setSubMenu] = useState(false)
    
    const user = false

    const displaySubMenu = () =>{
        setSubMenu(prevState => !prevState)
    }

const FoodsMenu = ()=>{
    return(
        <Box sx={{
            bgcolor: '#32CD32',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            minWidth: '15vw',
            padding: 1,
            borderRadius: 1,
            boxShadow: 1,
        }}>

            <NavLink to="/"><Typography variant="subTitle2">All menu</Typography></NavLink>
            <Typography variant="subTitle2">Breakfast</Typography>
            <Typography variant="subTitle2">Lunch</Typography>
            <Typography variant="subTitle2">Dinner</Typography>
            <Typography variant="subTitle2">Dessert</Typography>
        </Box>
    )
}

    return (
        <AppBar position="static" sx={{bgcolor: 'green'}}>
            <Toolbar sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // bgcolor: 'red'
                }}>
                    <ul style={styles.ul}>
                        <li><NavLink to="/">
                            <RestaurantMenuRounded style={{width: '48px', height: '48px',}}/>
                            </NavLink>
                        </li>
                        <li style={styles.li}><NavLink to="/"><Typography variant="h5">3-Steps Foods</Typography></NavLink></li>
                    </ul>
                </Box>
                <Box>
                    <ul style={styles.ul}>
                        <li style={styles.li}>
                            <NavLink onClick={displaySubMenu}>
                                <Typography variant="subTitle1">
                                        Foods
                                </Typography>
                                {/* <span><MoreVert /></span> */}
                                {
                                    subMenu && <FoodsMenu />
                                }
                            </NavLink>
                        </li>
                        <li style={styles.li}><NavLink><Typography variant="subTitle1">Search</Typography></NavLink></li>
                        <li style={styles.li}><NavLink><Typography variant="subTitle1">About Us</Typography></NavLink></li>
                        <li style={styles.li}><NavLink><Typography variant="subTitle1">Contact Us</Typography></NavLink></li>
                            { user ? (
                                <li style={styles.li}><NavLink to="/"><Typography variant="subTitle1">Logout</Typography></NavLink></li>
                                ) : (
                                <li style={styles.li}><NavLink to="/loginform"><Typography variant="subTitle1">Login</Typography></NavLink></li>
                            )}
                        <li style={styles.li}><NavLink><ShoppingCart /></NavLink></li>
                    </ul>
                </Box>
            </Toolbar>

        </AppBar>
    )
}

export default HeaderDesktop
