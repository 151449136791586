
import {
    styled,
    // Link
} from '@mui/material'



import {
    Link
} from 'react-router-dom'


export const NavLink = styled(Link)(
    ({theme}) =>({
        textDecoration: 'none',
        color: 'inherit',
        // color: 'red'
    })
)


export const styles = {
    ul: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        listStyle: 'none',
    },
    li: {
        marginRight: '20px'
    }
}
 