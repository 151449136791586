import React,{useState, useEffect} from 'react';
import axios from 'axios';


import {
    useLocation
} from 'react-router-dom'


import {
    Typography,
    Box,
    Grid,
    Button,
} from '@mui/material'


import {
    ArrowLeftOutlined
} from '@material-ui/icons'


import { NavLink } from '../../styles/Links'


const DetailPage = () => {
    const location = useLocation()
    const path = location.pathname.split("/")[2]
    // console.log(path)

    const [menu, setMenu] = useState({})

    useEffect(()=>{
        const theMenu = async()=>{
            const res = await axios.get('/api/menu/' + path)
            setMenu(res.data)
            // console.log(res)
        }
        theMenu()
    },[path])

    
    return (
        <Box sx={{
            position: 'relative',
            mt: 2,
        }}>
            <Typography variant="h4" textAlign="center" sx={{
                fontFamily: 'cursive'
            }}>{menu.foodName}</Typography>
            <Typography variant="h6" textAlign="center" color="darkgray">{`N${menu.price}.00`}</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 1,
            }}>
                <table>
                    <tr>
                        <td><Typography variant="h6"> Ingrident:</Typography></td>
                        <td>{menu.ingridentDetail}</td>
                    </tr>
                    <tr>
                        <td><Typography variant="h6">Preparation:</Typography></td>
                        <td>{menu.preparation}</td>
                    </tr>
                    <tr>
                        <td><Typography variant="h6">Preservation:</Typography></td>
                        <td>{menu.preservation}</td>
                    </tr>
                    <tr>
                        <td><Button variant="contained" color="success">Order</Button></td>
                        <td><Button variant="contained" color="primary">Customize your order</Button></td>
                    </tr>
                </table>
            </Box>
            <Box sx={{
                position: 'absolute',
                // padding: '8px',
                borderRadius: '50%',
                boxShadow: 4,
                bgcolor: 'green',
                top: 50,
                left: '90%',

                cursor: 'pointer'
            }}>
               <NavLink to="/" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',}}>
                    <ArrowLeftOutlined style={{color: 'white', width: '48px', height: '48px'}} />
               </NavLink> 
            </Box>
        </Box>
    )
}

export default DetailPage
