import React,{useContext, useState, useEffect} from 'react';
import {Context} from '../../context/Context'


import {
    Grid,
    TextField,
    Button,
    Paper,
    Box,
} from '@mui/material'


import {
    AddAPhoto
} from '@material-ui/icons'
 

const MenuForm = () => {
    const {previewForm, submitForm, setCreateMenu, createMenu,} = useContext(Context)

    // const HandleImagePreview = (e) =>{
    //     const foodImage = e.createMenu.foodPhoto.files[0]
    //     const imageMimeType = /image\/(png|jpg|jpeg)/i;
    //     if(!foodImage.type.match(imageMimeType)){
    //         alert("Image mime is not valid")
    //         return
    //     }
        
    //     return(
    //         <>
    //             <h4>{foodImage}</h4> 
    //         </>
    //     )
    // }

    const handleChange = (e) =>{
        const {name, value } = e.target;
        setCreateMenu(prevInput =>{
            return {
                ...prevInput,
                [name]: value
            }
        }) 
    }



    return (
        <Paper sx={{
            width: {md: '60vw', xs: '90vw'},
            margin: 'auto',
            bgcolor: 'none',
            mt: 4,
            mb: 8,
            padding: 2,
        }}>
            <form onSubmit={previewForm}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <label htmlFor="foodPic">
                                <AddAPhoto style={{color: 'green',}} />
                            </label>
                            <TextField 
                                id="foodPic"
                                fullWidth
                                type="file"
                                name="foodPhoto"
                                onChange={ handleChange}
                                sx={{display: 'none'}}                      
                            />
                              {/* <input id="foodPic"
                                fullWidth
                                type="file"
                                name="foodPhoto"
                                onChange={ handleChange} 
                                /> */}
                            <span style={{color: 'gray',}}>Upload food image</span>
                            {/* <HandleImagePreview /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            fullWidth
                            label="Food name"
                            name="foodName"
                        onChange={ handleChange}                      
                        />
                   
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            fullWidth
                            type="number"
                            label="Food price"
                            name="foodPrice"
                           onChange={ handleChange}                       
                           />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            fullWidth
                            label="Basic ingrident"
                            multiline
                            minRows="2"
                            name="basicIngrident"
                           onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            fullWidth
                            label="Detail ingrident"
                            multiline
                            minRows="4"
                            name="detailIngrident"
                         onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            fullWidth
                            label="Preparation"
                            multiline
                            minRows="4"
                            name="preparation"
                          onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField 
                            fullWidth
                            label="Preservation"
                            multiline
                            minRows="2"
                            name="Preservation"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button variant="contained"  type="submit" color="secondary">Preview</Button>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12}>
                        <table>
                            <tr>
                                <td>
                                    {createMenu.foodName}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {createMenu.foodPrice}
                                </td>
                            </tr>
                        </table>
                    </Grid> */}
                    </Grid> 
            </form>
        </Paper>
    )
}

export default MenuForm
