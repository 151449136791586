import React from 'react'
import ReactDom from 'react-dom'

import App from './App'

import MenuFormProvider from './context/Context'



ReactDom.render(
    <MenuFormProvider>
        <App/> 
    </MenuFormProvider>
,document.getElementById('root'));
