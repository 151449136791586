

import { useTheme, useMediaQuery } from '@mui/material';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

export default function NavHeader (){
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <>
            {matches ? <HeaderMobile /> : <HeaderDesktop />}
        </>
    )
}