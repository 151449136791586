import React from 'react'

import {
    AppBar,
    Toolbar,
    Box,
    Typography,
} from '@mui/material'


import {
    ShoppingCart,
    RestaurantMenuRounded,
    MoreVert,
    Menu,
} from '@material-ui/icons'


import { NavLink, styles } from '../../styles/Links'


{
    <ul style={styles.ul}>
                        <li>
                            <NavLink>
                                <Typography variant="subTitle1">
                                        Foods
                                </Typography>
                                {/* <span><MoreVert /></span> */}
                            </NavLink>
                        </li>
                        <li style={styles.li}><NavLink><Typography variant="subTitle1">Search</Typography></NavLink></li>
                        <li style={styles.li}><NavLink><Typography variant="subTitle1">About Us</Typography></NavLink></li>
                        <li style={styles.li}><NavLink><Typography variant="subTitle1">Contact Us</Typography></NavLink></li>
                        <li style={styles.li}><NavLink><Typography variant="subTitle1">Login</Typography></NavLink></li>
                        <li style={styles.li}><NavLink><ShoppingCart /></NavLink></li>
                    </ul>
}

const HeaderMobile = () => {
    return (
<AppBar position="static" sx={{bgcolor: 'green'}}>
            <Toolbar sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <ul style={styles.ul}>
                        <li><NavLink to="/">
                            <RestaurantMenuRounded style={{width: '48px', height: '48px', marginRight: 1}}/>
                            </NavLink>
                        </li>
                        <li style={styles.li}><NavLink to="/"><Typography variant="h5">3-Steps Foods</Typography></NavLink></li>
                    </ul>
                </Box>
                <Box>
                    <Menu />
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default HeaderMobile
